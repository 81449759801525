/* BASE COLORS */
export const RED = '#f05b4c';
export const GRAY = '#393939';
export const WHITE = '#ffffff';
export const BLACK = '#000000';

/* BACKGROUND */
export const BACKGROUND_RED = RED;
export const BACKGROUND_GRAY = GRAY;
export const BACKGROUND_WHITE = WHITE;

/* TEXT */
export const TEXT_RED = RED;
export const TEXT_GRAY = GRAY;
export const TEXT_WHITE = WHITE;
export const TEXT_BLACK = BLACK;
