import { TEXT_WHITE } from 'styles/colors';

import Typed from 'typed.js';

import React, { PureComponent, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';

import { Title } from 'components/title';

// prettier-ignore
const TYPED_OPTS = {
	startDelay: 1000,
	backDelay: 1000,
	typeSpeed: 100,
	backSpeed: 75,
	strings: [
		'webd^100evel^50oper',
		'webdesig^50ner',
		'tech ^100enthusiast',
		'epic',
		'good enough',
		'swedish',
		'Satoshi Nakamoto',
		'fun',
		'creative',
		'in ketosis',
		'aspiring ketoterian',
		'aware',
		'an optimistic nihilist',
		'only human',
		'khan.',
	],
	// loop: true,
	showCursor: false,
};

export class AnimatedTitle extends PureComponent {
	static propTypes = {
		as: PropTypes.any,
		text: PropTypes.string,

		color: PropTypes.string,
		shadowColor: PropTypes.string,
	};

	static defaultProps = {
		as: 'h1',
		color: TEXT_WHITE,
	};

	/* LIFECYCLE */
	componentDidMount() {
		const ref = this.khanTextRef.current;

		this.typed = new Typed(ref, TYPED_OPTS);
	}

	khanTextRef = createRef();

	/* RENDERING */
	renderText() {
		const { text } = this.props;

		if (text) return text;

		return (
			<Fragment>
				{'I am '}
				<span ref={this.khanTextRef}>khan.</span>
			</Fragment>
		);
	}

	render() {
		const { as, color, shadowColor } = this.props;

		const titleProps = {
			as,
			color,
			shadowColor,
		};

		// prettier-ignore
		return (
			<Title {...titleProps}>
				{this.renderText()}
			</Title>
		);
	}
}
