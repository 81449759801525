import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import styled from 'styled';

import GlobalStyle from 'styles/global';

import { LayoutThemeProvider } from '@schlagerkhan/styled-components-layout';

import { routes } from 'pages/routes';

import { Footer } from 'components/layout';

const Layout = styled.Layout``;

export class App extends Component {
	renderRoute(route) {
		const key = `route-${route.name}`;

		return <Route key={key} {...route} />;
	}

	render() {
		// prettier-ignore
		return (
			<LayoutThemeProvider>
				<Layout>
					<GlobalStyle />
					<Switch>
						{routes.map(this.renderRoute)}
					</Switch>
					<Footer />
				</Layout>
			</LayoutThemeProvider>
		);
	}
}
