import PropTypes from 'prop-types';

import styled, { css } from 'styled';

function textColor({ color }) {
	if (!color) return null;

	return css`
		color: ${color};
	`;
}

export const Title = styled.h2`
	width: 100%;

	font-family: Questrial, sans-serif;
	font-weight: 500;
	text-align: center;

	font-size: 32px;
	line-height: 32px;
	letter-spacing: 2px;

	${textColor};
	${styled.mixins.textShadow};

	${styled.minMedia.laptop} {
		font-size: 48px;
		line-height: 48px;
		letter-spacing: 4px;
	}
`;

Title.propTypes = {
	color: PropTypes.string,
};

styled.Title = styled(Title);
