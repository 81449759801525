import Home from './home';
import Page404 from './404';

export const routes = [
	{
		name: 'home',
		path: '/',
		exact: true,
		component: Home,
	},
	{
		component: Page404,
	},
];
