const NODE_ENV = process.env.NODE_ENV; // eslint-disable-line
const RUNTIME_ENV = process.env.RUNTIME_ENV; // eslint-disable-line
const _PORT = process.env.PORT;
const _HMR_PORT = process.env.HMR_PORT;

export const IS_DEV = NODE_ENV === 'development';
export const IS_PROD = NODE_ENV === 'production';

export const IS_LOCAL_RUNTIME = RUNTIME_ENV === 'local';
export const IS_STAGE_RUNTIME = RUNTIME_ENV === 'stage';
export const IS_PROD_RUNTIME = RUNTIME_ENV === 'production';

export const PORT = parseInt(_PORT);
export const HMR_PORT = parseInt(_HMR_PORT);

/* GRAPHQL */
export const GRAPHQL_ENDPOINT = '';
