import React from 'react';

import { BACKGROUND_GRAY, TEXT_WHITE, TEXT_GRAY } from 'styles/colors';

import styled from 'styled';

import { Section } from '../section';
import Items from './items';

const Wrapper = styled(Section)`
	color: ${TEXT_WHITE};
	background-color: ${BACKGROUND_GRAY};
`;

const Content = styled.Content``;

const Title = styled.Title``;

const InnerContent = styled.InnerContent`
	width: 100%;
`;

const Text = styled.p`
	text-align: center;
	color: ${TEXT_GRAY};
`;

export const Portfolio = () => (
	<Wrapper>
		<Content>
			<Title>PORTFOLIO</Title>
			<InnerContent>
				<Items />
				<Text>Ellen</Text>
			</InnerContent>
		</Content>
	</Wrapper>
);
