import { TEXT_GRAY } from 'styles/colors';

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    *, *:before, *:after {
        box-sizing: border-box;
    }

    html, body {
        margin: 0;
        padding: 0;

        font-family: 'Raleway', sans-serif;
        font-display: fallback;
        letter-spacing: 3px;

        color: ${TEXT_GRAY};
    }

    h1, h2, h3, h4, h5 {
        margin: 0;

        font-family: 'Questrial', sans-serif;
        letter-spacing: 3px;

        text-align: center;
    }

    p {
        margin: 0;
    }

    a {
        text-decoration: none !important;
    }
`;

export default GlobalStyle;
