import styled from 'styled';

export * from './content';
export * from './footer';

const Layout = styled.Layout`
	min-height: 100vh;
	height: auto;
	width: 100%;
`;

styled.Layout = styled(Layout);
