import { withRouter } from 'react-router-dom';

import * as GA from './ga';

function trackPageview(location) {
	const page = location.pathname;

	GA.set({ page });
	GA.pageview(page);
}

export const Tracker = withRouter((props) => {
	const { history, children } = props;

	trackPageview(window.location);
	history.listen(location => trackPageview(location));

	return children;
});

export { GA };
