import styled from 'styled';

import { Title } from 'components/title';

export const Section = styled.section`
	width: 100%;

	padding-top: 124px;
	padding-bottom: 124px;

	${Title} {
		margin-bottom: 24px;

		${styled.minMedia.laptop} {
			margin-bottom: 48px;
		}
	}
`;
