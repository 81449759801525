import styled, { css } from 'styled';

import { TEXT_BLACK } from './colors';

styled.mixins = {};

styled.mixins.createTextShadow = (opts = {}) => {
	return (props) => {
		const shadowColor = props.shadowColor || opts.color || TEXT_BLACK;
		const offset = opts.offset || '4px';

		return css`
			text-shadow: ${offset} ${offset} ${shadowColor}40;
		`;
	};
};

styled.mixins.textShadow = styled.mixins.createTextShadow();
