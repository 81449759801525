import React from 'react';
import PropTypes from 'prop-types';

import { WHITE } from 'styles/colors';

import styled, { css } from 'styled';

export const SIZE = 146;
const HALF = SIZE / 2;
const OFFSET = 16;
const TOTAL = SIZE + OFFSET;

function mobileItemMargin() {
	return css`
		&:nth-child(2n) {
			margin-top: ${OFFSET}px;
		}
		&:nth-child(2) {
			margin-top: ${TOTAL / 2}px;
		}
		&:nth-child(2n + 3) {
			margin-top: -${HALF - OFFSET / 2}px;
		}
	`;
}

function desktopItemMargin({ index }) {
	const marginTop = (index % 4) * 16;

	return css`
		margin-top: ${marginTop}px;
	`;
}

function imageSize(props) {
	const { size } = props;

	return css`
		max-width: ${size}px;
		max-height: ${size}px;
	`;
}

const ItemWrapper = styled.a`
	position: relative;
	width: ${SIZE}px;
	height: ${SIZE}px;

	border: 8px solid ${WHITE};
	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	${styled.maxMedia.laptop} {
		${mobileItemMargin};
	}
	${styled.minMedia.laptop} {
		${desktopItemMargin}
	}
`;

const ItemImage = styled.img`
	${imageSize}
`;

// prettier-ignore
export const Item = ({ index, href, image, size }) => (
	<ItemWrapper index={index} href={href} target='_blank'>
		<ItemImage src={image} size={size} />
	</ItemWrapper>
);

Item.propTypes = {
	index: PropTypes.number.isRequired,
	href: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	size: PropTypes.number,
};

Item.defaultProps = {
	size: 60,
};
