import styled, { css } from 'styled';

function variantStyle({ variant = 'medium' }) {
	switch (variant) {
		case 'large':
			return null;
		case 'medium':
			return css`
				max-width: 700px;
			`;
		case 'small':
			return css`
				max-width: 420px;
			`;
	}

	throw new Error(`No such InnerContent variant: ${variant}`);
}

export const InnerContent = styled.div`
	width: 100%;
	margin: 0 auto;

	${variantStyle};
`;

styled.InnerContent = styled(InnerContent);
