import React from 'react';

import { WHITE, BACKGROUND_RED, TEXT_WHITE } from 'styles/colors';

import styled from 'styled';

import { AnimatedTitle } from 'components/animated-title';

const Wrapper = styled.section`
	width: 100%;
	min-height: 100vh;

	background-color: ${BACKGROUND_RED};

	display: flex;
	flex-direction: column;
`;

const Content = styled.Content`
	flex: 1;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	color: ${TEXT_WHITE};
	font-weight: 300;

	h1 {
		margin-bottom: 24px;

		font-size: 36px;
		line-height: 36px;

		${styled.minMedia.laptop} {
			margin-bottom: 24px;

			font-size: 84px;
			line-height: 84px;
		}
	}
`;

const SubtitleWrapper = styled.div`
	min-width: 250px;
	margin: 0 auto;

	hr {
		width: 100%;
		margin: 0 auto 24px;

		border: 1px solid ${WHITE};
	}
`;

const Subtitle = styled.p`
	display: flex;
	justify-content: space-between;

	font-size: 12px;
	letter-spacing: 2px;
`;

export const Intro = () => {
	return (
		<Wrapper>
			<Content>
				<AnimatedTitle />
				<SubtitleWrapper>
					<hr />
					<Subtitle>
						<span>Developer</span>
						<span>| Designer |</span>
						<span>Enthusiast</span>
					</Subtitle>
				</SubtitleWrapper>
			</Content>
		</Wrapper>
	);
};
