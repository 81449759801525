import React, { Component } from 'react';

import styled from 'styled';

import { Intro } from './intro';
import { Portfolio } from './portfolio';
import { About } from './about';

const Wrapper = styled.div``;

export default class HomePage extends Component {
	render() {
		return (
			<Wrapper>
				<Intro />
				<Portfolio />
				<About />
			</Wrapper>
		);
	}
}
