import { Khan } from 'images/pictures';

import React from 'react';

import { BACKGROUND_WHITE } from 'styles';

import styled from 'styled';

import { Section } from './section';

const Wrapper = styled(Section)`
	width: 100%;

	background-color: ${BACKGROUND_WHITE};
	font-weight: 300;

	${styled.maxMedia.laptop} {
		padding-bottom: 0;
	}
`;

const Content = styled.Content``;

const Title = styled.Title`
	${styled.maxMedia.laptop} {
		${styled.mixins.createTextShadow({ offset: '2px' })}
	}
`;

const InnerContent = styled.InnerContent`
	display: flex;
	flex-direction: column-reverse;
	align-items: center;

	${styled.minMedia.laptop} {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
`;

const ImageWrapper = styled.div`
	display: flex;

	${styled.maxMedia.laptop} {
		width: 250px;
		height: 250px;
	}
`;
const Image = styled.img`
	width: 100%;
	height: 100%;
`;

const TextWrapper = styled.div`
	width: 100%;
	flex-shrink: 0;

	text-align: center;

	${styled.minMedia.laptop} {
		width: 400px;
		text-align: left;

		h3 {
			text-align: left;
		}
	}

	h3 {
		margin-bottom: 32px;

		font-weight: bold;
		font-size: 24px;
	}

	p {
		font-size: 16px;
		margin-bottom: 24px;
	}
`;

export const About = () => (
	<Wrapper>
		<Content>
			<Title>ABOUT</Title>
			<InnerContent>
				<ImageWrapper>
					<Image src={Khan.Orange} />
				</ImageWrapper>
				<TextWrapper>
					<h3>Hello there, I am Khan!</h3>
					<p>Tech and design entusiast from Sweden.</p>
					<p>
						This is my website. It is actually not much to see. But honestly, I'm pretty good at creating these things. Apps and websites,
						that is.
					</p>
					<p>
						But if you'd like I would love to chat with you about... anything really. Not that I don't have friends, I just like to talk
						to people about interesting stuff.
					</p>
					<p>So feel free as a bird to contact me in anyway you want to (check the footer).</p>
				</TextWrapper>
			</InnerContent>
		</Content>
	</Wrapper>
);
