import { Projects } from 'images/pictures';

import React from 'react';

import styled from 'styled';

import { Item, SIZE } from './item';

const { CompareRepair, BraMobil, Holo, Frodas } = Projects;

const Wrapper = styled.div`
	width: ${2 * SIZE}px;
	margin: 0 auto;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	${styled.minMedia.laptop} {
		width: 100%;
	}
`;

const ITEMS = [
	{
		image: CompareRepair,
		title: 'CompareRepair',
		href: 'https://www.comparerepair.se',
	},
	{
		image: BraMobil,
		title: 'BraMobil',
		href: 'https://www.bramobil.se',
	},
	// {
	// 	image: Projects.BraMobil,
	// 	title: 'MyBonus',
	// 	href: 'https://www.mybonus.com',
	// },
	{
		image: Holo,
		title: 'HoloHealth',
		href: 'https://holo.health',
	},
	{
		image: Frodas,
		size: 90,
		title: 'Frodas',
		href: 'https://frodas.io',
	},
];

export default () => (
	<Wrapper>
		{ITEMS.map((item, index) => {
			const key = `item-${index}`;

			return <Item key={key} index={index} {...item} />;
		})}
	</Wrapper>
);
