import { BACKGROUND_GRAY, BACKGROUND_WHITE, TEXT_WHITE } from 'styles/colors';
import { Github, Mail } from 'images/icons';

import React from 'react';

import styled from 'styled';

const Wrapper = styled.article`
	width: 100%;
	height: 250px;

	background-color: ${BACKGROUND_GRAY};

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h2 {
		font-size: 48px;
		color: ${TEXT_WHITE};
	}
`;

const Title = styled.Title`
	padding: 0 !important;
`;

const Links = styled.div`
	margin-top: 24px;

	display: flex;
	justify-content: center;
	align-items: center;
`;

const LinkWrapper = styled.a.attrs({
	target: '_blank',
})`
	width: 48px;
	height: 48px;

	margin-right: 12px;
	margin-left: 12px;

	padding: 12px;

	background-color: ${BACKGROUND_WHITE};
	border-radius: 50%;
`;

const LinkImage = styled.img`
	width: 100%;
`;

const Link = ({ image, href, ...restProps }) => (
	<LinkWrapper href={href} {...restProps}>
		<LinkImage src={image} />
	</LinkWrapper>
);

// eslint-disable-next-line
export const Footer = () => {
	return (
		<Wrapper>
			<Title as='h2'>I am khan.</Title>
			<Links>
				<Link image={Github} href='https://github.com/SchlagerKhan' />
				<Link image={Mail} href='mailto:hello@iamkhan.io' />
			</Links>
		</Wrapper>
	);
};
